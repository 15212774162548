<template>
  <el-row>
    <el-col :span="8">
      <el-select style="width: 95%"
                 v-model="value.subjectType"
                 disabled>
        <!-- :disabled="value.subjectType === '1'" -->
        <el-option key="1"
                   label="企业"
                   value="1"></el-option>
        <el-option key="2"
                   label="个人"
                   value="2"></el-option>
      </el-select>
    </el-col>
    <el-col :span="16">
      <div v-if="value.subjectType === '2'">
        <el-select v-model="value.psnId"
                   v-if="choice"
                   filterable
                   :placeholder="'请输入关键字'"
                   style="width: 100%"
                   @change="choiceChange"
                   remote
                   multiple
                   clearable>
          <el-option v-for="item in options"
                     :key="item.code"
                     :label="item.name + '-' + item.username"
                     :value="item.value"></el-option>
        </el-select>

        <el-select v-if="!choice"
                   style="width: 100%"
                   v-model="value.psnId"
                   clearable
                   @change="inputChange"
                   filterable
                   v-selectLoadMore="selectLoadMore"
                   :loading="loading"
                   remote
                   :remote-method="remoteMethod"
                   placeholder="请输入关键字">
          <el-option v-for="item in options"
                     :key="item.code"
                     :label="item.name + '-' + item.username+`-(${item.enterprise.name})`"
                     :value="item.value"></el-option>
        </el-select>

        <!-- <el-select
          v-if="!choice"
          v-model="value.psnId"
          filterable
          :placeholder="'请输入关键字1'"
          style="width: 100%"
         @change="inputChange"
         
          :remote-method="remoteMethod"
          clearable
          remote
          reserve-keyword
          value-key="code"
        >
          <el-option
            v-for="item in options"
            :key="item.code"
            :label="item.name + '-' + item.username"
            :value="item.value"
          ></el-option>
        </el-select>-->
      </div>
      <div v-if="value.subjectType === '1'">
        <el-select v-model="value.psnId"
                   filterable
                   :placeholder="'请输入关键字'"
                   style="width: 100%"
                   @change="inputChange1">
          <el-option v-for="item in optionsList"
                     :key="item.code"
                     :label="item.user.name + '-' + item.user.phone"
                     :disabled="item.show"
                     :value="item.user.esignAccount"></el-option>
        </el-select>
      </div>
    </el-col>
  </el-row>
</template>

<script>

import { api } from '/src/api/base';
export const UserSimpleList = api()('staff.list.json');
// export const EnterpriseAdminList = api('')(
//   'enterprise.admin.list.json'
// );
export const contractSingerList = api()(
  'contractSinger.list.json'
);
export default {
  props: {
    value: Object,
    choice: Boolean,
    obj: Array
    // key code,
  },
  data () {
    return {
      options: [

      ],
      optionsList: [],
      enterpriseCode: '',
      readUserPageList: [],
      totalPage: '',

      pageNumber: 1,
      pageSize: 10,
      keywords: '',
      loading: false

    };
  },
  mounted () {
    this.remoteMethod()
  },
  methods: {
    // 下拉加载更多
    async selectLoadMore () {
      console.log(222);
      this.pageNumber = this.pageNumber + 1;
      if (this.pageNumber > this.totalPage) return;
      await this.readAllUsers(); // 请求接口
    },
    // 远程搜索
    async remoteMethod (query) {
      this.keywords = query;
      this.pageNumber = 1;

      if (this.value.subjectType === '1') {
        this.getEnterpriseList();
      }
      if (this.value.subjectType === '2') {
        await this.readAllUsers(); // 请求接口
      }


    },
    // 获取数据
    async readAllUsers () {
      let params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        f_like_name: this.keywords,
        isAuthenticated: true
      }
      const res = await UserSimpleList(params)

      this.totalPage = Math.ceil(res.total / 10);
      this.total = res.total;
      this.options = this.options.concat(res.list).map(it => {
        return {
          ...it,

          label: it.name,
          value: it.esignAccount
        };
      });

    },

    async inputChange1 (val) {
      this.enterpriseCode = val

      const a = this.optionsList.find(
        it => it.user.esignAccount === val
      )
      this.value.psnName = a.user.name;
      console.log(this.value.psnName);
      this.$emit('change', this.value);

    },
    inputChange (val) {

      if (val) {

        const a = this.options.filter(
          it => it.esignAccount === val
        )[0]
        console.log(a);
        this.value.psnName = a.name;
        if (a.staff) {
          this.value.staffCode = a.staff.code || ''
        }
        else {
          this.value.staffCode = ''
        }


      }
      else {
        this.value.psnName = '';
        this.value.staffCode = ''
      }

      this.$emit('change', this.value);
    },
    choiceChange (val) {
      this.value.psnName = this.options.filter(item => val.some(i => item.value === i)).map(_it => { return _it.name })
      this.value.staffCode = this.options.filter(item => val.some(i => item.value === i)).map(_it => {
        if (_it.staff) {
          return _it.staff.code
        }
        else {
          return ''
        }
      })
      this.$emit('change', this.value);
    },
    // async remoteMethod (keywords) {
    //   if (this.value.subjectType === '1') {
    //     this.getEnterpriseList();
    //   }
    //   if (this.value.subjectType === '2') {
    //     this.getUserList(keywords);
    //   }
    // },
    async getUserList (keywords) {
      console.log(this.value.psnName);
      const users = await UserSimpleList({
        pageNumber: 1,
        pageSize: 10,
        f_like_name: keywords || '',
        isAuthenticated: true
      });

      this.options = users.list.filter(i => i.esignAccount != '').map(it => {
        return {
          ...it,

          label: it.name,
          value: it.esignAccount
        };
      });

      console.log(this.options)

    },
    async getEnterpriseList () {

      const enterprises = await contractSingerList({

        authStatus: '1',
        pageNumber: 1,
        pageSize: 10,
      });

      this.optionsList = enterprises.list.map(it => {
        return {
          ...it,
          // orgName: window.localStorage.getItem('enterpriseName'),
          // orgId: window.localStorage.getItem('esignAccount')
        };
      });

    }
  }
};
</script>

<style></style>
